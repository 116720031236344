export var RankedStatus;
(function (RankedStatus) {
    RankedStatus[RankedStatus["graveyard"] = -2] = "graveyard";
    RankedStatus[RankedStatus["workInProgress"] = -1] = "workInProgress";
    RankedStatus[RankedStatus["pending"] = 0] = "pending";
    RankedStatus[RankedStatus["ranked"] = 1] = "ranked";
    RankedStatus[RankedStatus["approved"] = 2] = "approved";
    RankedStatus[RankedStatus["qualified"] = 3] = "qualified";
    RankedStatus[RankedStatus["loved"] = 4] = "loved";
})(RankedStatus || (RankedStatus = {}));
export function hasLeaderboard(beatmapset) {
    return beatmapset.ranked_status > 0;
}
