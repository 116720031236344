export var GameMode;
(function (GameMode) {
    GameMode[GameMode["osu"] = 0] = "osu";
    GameMode[GameMode["taiko"] = 1] = "taiko";
    GameMode[GameMode["catch"] = 2] = "catch";
    GameMode[GameMode["mania"] = 3] = "mania";
})(GameMode || (GameMode = {}));
export const gameModes = [0, 1, 2, 3];
const gameModeShortNames = ['osu', 'taiko', 'fruits', 'mania'];
const gameModeLongNames = ['osu!standard', 'osu!taiko', 'osu!catch', 'osu!mania'];
export function gameModeShortName(gameMode) {
    return gameModeShortNames[gameMode];
}
export function gameModeFromShortName(shortName) {
    if (shortName == null) {
        return null;
    }
    const gameMode = gameModeShortNames.indexOf(shortName);
    return gameMode < 0 ? null : gameMode;
}
export function gameModeLongName(gameMode) {
    return gameModeLongNames[gameMode];
}
