export var ConsentValue;
(function (ConsentValue) {
    ConsentValue[ConsentValue["no"] = 0] = "no";
    ConsentValue[ConsentValue["yes"] = 1] = "yes";
    ConsentValue[ConsentValue["unreachable"] = 2] = "unreachable";
})(ConsentValue || (ConsentValue = {}));
export var CreatorsState;
(function (CreatorsState) {
    CreatorsState[CreatorsState["unchecked"] = 0] = "unchecked";
    CreatorsState[CreatorsState["checkedOnlyByCaptain"] = 1] = "checkedOnlyByCaptain";
    CreatorsState[CreatorsState["good"] = 2] = "good";
})(CreatorsState || (CreatorsState = {}));
export var DescriptionState;
(function (DescriptionState) {
    DescriptionState[DescriptionState["notReviewed"] = 0] = "notReviewed";
    DescriptionState[DescriptionState["reviewed"] = 1] = "reviewed";
})(DescriptionState || (DescriptionState = {}));
export var LogType;
(function (LogType) {
    LogType[LogType["apiServerStarted"] = 0] = "apiServerStarted";
    LogType[LogType["loggedIn"] = 1] = "loggedIn";
    LogType[LogType["loggedOut"] = 2] = "loggedOut";
    LogType[LogType["userCreated"] = 3] = "userCreated";
    LogType[LogType["userUpdated"] = 4] = "userUpdated";
    LogType[LogType["roleCreated"] = 5] = "roleCreated";
    LogType[LogType["roleDeleted"] = 6] = "roleDeleted";
    LogType[LogType["roleToggledAlumni"] = 7] = "roleToggledAlumni";
    LogType[LogType["mapperConsentCreated"] = 8] = "mapperConsentCreated";
    LogType[LogType["mapperConsentUpdated"] = 9] = "mapperConsentUpdated";
    LogType[LogType["mapperConsentBeatmapsetCreated"] = 10] = "mapperConsentBeatmapsetCreated";
    LogType[LogType["mapperConsentBeatmapsetDeleted"] = 11] = "mapperConsentBeatmapsetDeleted";
    LogType[LogType["mapperConsentBeatmapsetUpdated"] = 12] = "mapperConsentBeatmapsetUpdated";
    LogType[LogType["settingUpdated"] = 13] = "settingUpdated";
    LogType[LogType["extraTokenCreated"] = 14] = "extraTokenCreated";
    LogType[LogType["extraTokenDeleted"] = 15] = "extraTokenDeleted";
    LogType[LogType["pollCreated"] = 16] = "pollCreated";
    LogType[LogType["pollUpdated"] = 17] = "pollUpdated";
    LogType[LogType["submissionDeleted"] = 18] = "submissionDeleted";
    LogType[LogType["reviewCreated"] = 19] = "reviewCreated";
    LogType[LogType["reviewDeleted"] = 20] = "reviewDeleted";
    LogType[LogType["reviewUpdated"] = 21] = "reviewUpdated";
    LogType[LogType["beatmapsetCreated"] = 22] = "beatmapsetCreated";
    LogType[LogType["beatmapsetDeleted"] = 23] = "beatmapsetDeleted";
    LogType[LogType["beatmapsetSoftDeleted"] = 24] = "beatmapsetSoftDeleted";
    // nominationCreated,
    // nominationDeleted,
})(LogType || (LogType = {}));
export var MetadataState;
(function (MetadataState) {
    MetadataState[MetadataState["unchecked"] = 0] = "unchecked";
    MetadataState[MetadataState["needsChange"] = 1] = "needsChange";
    MetadataState[MetadataState["good"] = 2] = "good";
})(MetadataState || (MetadataState = {}));
export var ModeratorState;
(function (ModeratorState) {
    ModeratorState[ModeratorState["unchecked"] = 0] = "unchecked";
    ModeratorState[ModeratorState["needsChange"] = 1] = "needsChange";
    ModeratorState[ModeratorState["sentToReview"] = 2] = "sentToReview";
    ModeratorState[ModeratorState["good"] = 3] = "good";
    ModeratorState[ModeratorState["notAllowed"] = 4] = "notAllowed";
})(ModeratorState || (ModeratorState = {}));
export var PacksState;
(function (PacksState) {
    PacksState[PacksState["notUploaded"] = 0] = "notUploaded";
    PacksState[PacksState["uploadedInitial"] = 1] = "uploadedInitial";
    PacksState[PacksState["uploadedFinal"] = 2] = "uploadedFinal";
})(PacksState || (PacksState = {}));
export var Role;
(function (Role) {
    Role[Role["admin"] = 0] = "admin";
    Role[Role["captain"] = 1] = "captain";
    Role[Role["metadata"] = 2] = "metadata";
    Role[Role["moderator"] = 3] = "moderator";
    Role[Role["newsEditor"] = 4] = "newsEditor";
    Role[Role["developer"] = 5] = "developer";
    Role[Role["spectator"] = 6] = "spectator";
    Role[Role["video"] = 7] = "video";
    Role[Role["newsAuthor"] = 8] = "newsAuthor";
    Role[Role["packUploader"] = 9] = "packUploader";
})(Role || (Role = {}));
